<template>
  <div
    v-if="modalStore.isVisible"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    aria-labelledby="preventiveCheckupModalLabel"
    aria-describedby="preventiveCheckupModalDescription"
    class="fixed left-0 right-0 top-0 z-50 flex h-[100%] w-full overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-30 p-4 md:inset-0">
    <div class="relative m-auto w-full lg:max-w-md">
      <!-- Modal content -->
      <div class="relative flex flex-col rounded-lg bg-white p-6 shadow">
        <!-- Modal header -->
        <div class="mb-2 flex items-center justify-between rounded-t md:mb-4">
          <h3 id="preventiveCheckupModalLabel" class="text-2xl leading-tight md:text-3xl">Vorsorgecheck</h3>
          <button
            type="button"
            class="ml-auto rounded-lg bg-transparent text-gray-900 hover:bg-gray-100"
            @click="toggleModal">
            <SvgoXMark class="text-4xl" />
            <span class="sr-only">Schließen</span>
          </button>
        </div>
        <p id="preventiveCheckupModalDescription" class="text-sm leading-relaxed text-gray-900 md:text-base">
          Unser haelsi Vorsorgecheck beinhaltet auch alle Untersuchungen der gesetzlichen Gesundenuntersuchung (und noch
          viel mehr). Solltest du die Gesundenuntersuchung in den letzten 12 Monaten in Anspruch genommen haben oder sie
          woanders machen wollen, müssen wir {{ transformToCurrency(additionalPreventiveCareFee) }} verrechnen.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { additionalPreventiveCareFee } from "~/constants/preventiveCare";
import { usePreventiveCheckupModalStore } from "~/store/preventiveCheckupModal";
import { transformToCurrency } from "~/util/transformToCurrency";

const modalStore = usePreventiveCheckupModalStore();
const toggleModal = () => {
  modalStore.toggleModal();
};
</script>

<style lang="scss" scoped></style>
